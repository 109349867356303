import React from "react"
import { Link, graphql } from "gatsby"
import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import { GlobalStyle, Text } from "pretty-decent-ui";
import Header from "../../components/header";
import SEO from "../../components/seo";
import Footer from "../../components/Footer";
import theme from "../../theme-posts";

import "../../components/layout.css"

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  line-height: inherit;
  min-height: 85vh;
  color: ${props => props.theme.colors.gray[900]};
`

const BlogPostTitleLink = styled.div`
  color: ${props => props.theme.colors.gray[800]};
  &:hover {
    color: ${props => props.theme.colors.purple[700]};
  }
`

const H2 = ({ children }) => <Text as="h2" className="mt-12 mb-4" fontWeight={600} xs="2xl" md="3xl">{children}</Text>


const BlogIndex = ({ data }) => {
  const { edges: posts } = data.allMdx
  return (

    <ThemeProvider theme={theme}>
      <SEO title="All my posties" />
      <GlobalStyle />
      <Container>
        <Header />

        <ul>
          {posts.map(({ node: post }) => (
            <li key={post.id}>
              <BlogPostTitleLink as={Link} to={post.fields.slug}>
                <H2>{post.frontmatter.title}</H2>
              </BlogPostTitleLink>
              <Text lineHeight={1.7} size="lg">{post.excerpt}</Text>
            </li>
          ))}
        </ul>
      </Container>
      <Footer />
    </ThemeProvider>
  )
}
export const pageQuery = graphql`
  query blogIndex {
    allMdx(
      filter: {frontmatter: {published: {eq: true}}},
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default BlogIndex
